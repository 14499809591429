.Programs {
    display: flex;
    padding: 0 2rem;
    flex-direction: column;
    gap: 2rem;

}

.programs-header {
    font-weight: bold;
    color: whitesmoke;
    font-size: 3rem;
    gap: 5rem;
    text-transform: uppercase;
    justify-content: space-between;
    font-style: italic;
    display: flex;
}
.programs-categories{
    display: flex;
    gap: 2rem;
    
}
.category{
    display: flex;
    flex-direction: column;
    background-color: grey;
    gap: 1rem;
    padding: 2rem 2rem;
    color: whitesmoke;
    justify-content: space-between;
} 
.category>:nth-child(1){
    width: 2rem;
    height: 2rem;
    fill: whitesmoke;
    
}
.category>:nth-child(2){
    font-weight: bold;
    font-size: 1.3rem;
    
}
.category>:nth-child(3){
    font-size: 0.9rem;
    line-height: 25px;
    
}
.join-now{
display: flex;
gap: 2rem;
align-items: center;
}
.join-now>img{
    height: 1.5rem;
    width: 1.5rem;
}
.category:hover{
    background-color: var(--orange);
    cursor: pointer;
}
@media screen and (max-width: 768px){
    .programs-header{
        flex-direction: column;
        gap: 1rem;
       
        align-items: center;
        justify-content: center;
        margin-top: 2rem;

    }
    .programs-categories{
        flex-direction: column;
        
    }
    
}