.Testimonials{
    display: flex;
    gap: 1rem;
    padding: 1rem;
}
.left-t{
    display: flex;
    color: whitesmoke;
    gap: 1rem;
    flex-direction: column;
    text-transform: uppercase;
    flex: 1.3;
   
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-size: 1.3rem;
  
}
.left-t>:nth-child(2){
    font-weight: 1.5rem;
    font-size: 2.5rem;
}
.left-t>:nth-child(3){
   font-size: 3.5rem;
    font-weight:bold;
}
.left-t>:nth-child(4){
   text-transform: lowercase;
   text-align: justify;
   letter-spacing: 2px;
   
}
.right-t{
    position:relative ;
    flex: 1;
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.right-t>:nth-child(1){
    border: 2px solid orange;
    position: relative;
    left: 12rem;
    top: 0.4em;
    width: 17rem;
    height: 20rem;
}
.right-t>:nth-child(2){
    position: relative;
    left: 17.5rem;
    top: -17rem;
    background-image: linear-gradient(to right,#FD9346, #FF6200);
    width: 15rem;
    height: 20rem;
}
.arrows{
    display: flex;
    gap: 1rem;
    position: relative;
    top: -22rem ;
}
.arrows>img{
    cursor: pointer;
    width: 1.5rem ;
}
@media screen and (max-width:768px ) {
    .Testimonials{
        flex-direction: column;
    }
    
    
    
}