.plans-header {
    gap: 2rem;
    display: flex;
    justify-content: space-between;
    font-size: 4rem;
    margin-top: -5rem;
    font-weight: bold;
    font-style: italic;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.plans-header>span:nth-child(2) {
    color: whitesmoke;
    margin-bottom: 1rem;
}

.plans-container {

    padding: 0 2rem;
    flex-direction: column;
    display: flex;
    gap: 4rem;
    position: relative;
}
.plans-blur-1{
    width: 23rem;
    height: 22rem;
    top: 6rem;
    left: 0rem;
}
.plans-blur-2{
    width: 23rem;
    height: 22rem;
    top: 10rem;
    right: 0rem;
}

.plans {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    padding: 0 5rem;
}

.plan {
    display: flex;
    flex-direction: column;
    background-color: var(--caloryCard);
    gap: 2rem;
    padding: 3rem 4rem;
    width: 15rem;
    color: whitesmoke;
    margin-bottom: 6rem;
    font-size: 1.1rem;
}
.plan:hover{
    transform: scale(1.2);

}


.plan:nth-child(2) {
    transform: scale(1.2);
    background-image: linear-gradient(to right,#FD9346, #FF6200);

}
.plan:nth-child(2):hover{
    transform: scale(1.3);
    background-image: linear-gradient(to right,#FD9346, #FF6200);

}
.plan>svg{
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
    .plan>:nth-child(3){
        font-size: 3rem;
    font-weight: bold;
    }
}
.plan>:nth-child(5){
    font-size: 0.8rem;
    flex-direction:column;
    gap: 1rem;
}
.features{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    align-items: center;
    display: flex;
    gap: 1rem;
}
.feature>img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
    fill: whitesmoke;
}
.plans>:nth-child(2)>button{
    color: var(--orange);
}
 @media screen and (max-width:768px ) {
    .plans{
        flex-direction: column;
        transform: scale(1.2);
        margin-top: 200px;  
        margin-bottom: 30px;
        
        
    }
    .plans-header{
        display: flex;
        flex-direction: column;
        align-items: center;
     
    }
    .plans>div>span{
        
        margin-top: -30px;
        
    }
    .plans>div>div{
        
        margin-top: -30px;
        line-height: 1;
        
    }
    .plans>div{
        display: flex;
        flex-direction: column;
        margin-top: -5rem;
        
        
        
    }
    
    
 }